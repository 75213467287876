import axios from '@/utils/http'

// 查询新闻列表
export const newList = function (data) {
    return axios.get('/power/cms/news/listPage', { params: data })
  }

  // 查询新闻详情
export const newDetail = function (id) {
    return axios.get('/power/cms/news/detail/'+id)
  }